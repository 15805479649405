export namespace PlaygroundHelper {
  export const KeyOfToken = 'playground-token';
  export const PersistToken = (value: string): void => {
    localStorage.setItem(PlaygroundHelper.KeyOfToken, value);
  }
  export const RetrieveToken = () : string | null => {
    return localStorage.getItem(PlaygroundHelper.KeyOfToken);
  }
  export const HasPersistedToken = (): boolean => {
    return !!localStorage.getItem(PlaygroundHelper.KeyOfToken);
  }
  export const HasOneProfileToken = (): boolean => {
    const token = PlaygroundHelper.RetrieveToken();
    if (token) {
      return token.includes('OneProfileToken');
    }
    return false
  }
}
