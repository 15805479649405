import {LoginAsRequestType} from "./login-as.model";
import {TypeOfToken} from "../playground";
import { OneProfilePayloadDto } from "@mods/shared";

export namespace LoginAsActions {
  export class Loading {
    static readonly type = '[Login As] Set As Loading';
  }

  export class Working {
    static readonly type = '[Login As] Set As Working';
  }

  export class Done {
    static readonly type = '[Login As] Set As Done';
  }

  export class Login {
    static readonly type = '[Login As] Login';

    constructor(public readonly request: LoginAsRequestType) {
    }
  }

  export class LoginSuccess {
    static readonly type = '[Login As] Login Success';
  }

  export class LoadApplicableTokensForHydration {
    static readonly type = '[Login As] Load Global Entity Token Used For Hydration';
  }
  export class ApplyTokenType {
    static readonly type = '[Login As] Apply Token Type';
    constructor(public readonly tokenType: TypeOfToken) {
    }
  }
  export class ApplyCurrentOneProfile {
    static readonly type = '[Login As] Apply Current One Profile';
    constructor(public readonly currentOneProfile: OneProfilePayloadDto) {

    }
  }
  export class TriggerSelectedProfileChange {
    static readonly type = '[Login As] Trigger Selected Profile Change';
    constructor(public readonly selectedProfileId: string) {
    }
  }
  export class ChangeCurrentSelectedProfileSelection {
    static readonly type = '[Login As] Change Current Selected Profile Selection';
    constructor(public readonly selectedProfileId: string) {
    }
  }
  export class ReEstablishOneProfileSelectedProfile {
    static readonly type = '[Login As] Re Establish One Profile Selected Profile';
  }

}


