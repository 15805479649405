import {ElementRef, Injectable, TemplateRef, ViewContainerRef} from "@angular/core";
import {CoreOverlayHelperService} from "./core-overlay-helper.service";
import {BehaviorSubject} from "rxjs";

export interface  CoreNotificationServiceConfig {
  elementRef: ElementRef<any>;
  viewContainerRef: ViewContainerRef,
  width: number;
}
export interface CoreNotificationDisplayConfig {
  delay: number;
}

@Injectable({
  providedIn: 'root'
})
export class CoreNotificationService {
  #configuration: CoreNotificationServiceConfig | undefined;
  #parentTemplateRef: TemplateRef<any> | undefined;
  public message$ = new BehaviorSubject<string>('placeholder');
  constructor(private readonly overlayHelperService: CoreOverlayHelperService) {
  }
  applyConfiguration(config: CoreNotificationServiceConfig) {
    this.#configuration = config;
  }
  setTemplateRef(parentTemplateRef: TemplateRef<any>) {
    this.#parentTemplateRef = parentTemplateRef;
  }
  setParentElementRef(parentElementRef: ElementRef<any>) {
    this.#configuration = this.#configuration || {} as CoreNotificationServiceConfig;
    this.#configuration.elementRef = parentElementRef;
  }
  displayMessage(message: string, config: CoreNotificationDisplayConfig) {
    this.message$.next(message);
    this.displayNotification(config);
  }
  displayNotification(config: CoreNotificationDisplayConfig) {
    if(this.#configuration && this.#parentTemplateRef) {
      this.overlayHelperService.displayOverlay({
        elementRef: this.#configuration.elementRef,
        viewContainerRef: this.#configuration.viewContainerRef,
        templateRef: this.#parentTemplateRef,
        width: this.#configuration.width,
        delay: config.delay
      });
    }

  }



}
