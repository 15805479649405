import {Injectable} from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private readonly cryptoKey: string = '9op238s7';
  constructor() {}

  public encrypt(s: string): string {
    return this.set(this.cryptoKey, s);
  }

  public decrypt(s: string): null | string {
    return this.get(this.cryptoKey, s);
  }

  public encodeText(s: string): string {
    // + character isn't allowed using WebUtility.UrlDecode added work around
    const searchRegExp = new RegExp('\\+', 'g');
    s = s.replace(searchRegExp, '_PLUS_');
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(s));
  }

  //The set method is use for encrypt the value.
  private set(keys: string, value: string) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  private get(keys: string, value: string) {
    if (!value) {
      return null;
    }
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    const result = decrypted.toString(CryptoJS.enc.Utf8);

    return !result ? null : result;
  }
}
