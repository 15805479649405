import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ILoginAsStateModel,
  LoadedTokensForHydrationType,
  LoginAsRequestType,
  LoginAsTokenResponse,
  LoginAsType
} from './login-as.model';
import {LoginAsActions} from './login-as.actions';
import {CryptoService} from "../../shared/services";
import {forkJoin, mergeMap, tap} from "rxjs";
import {PlaygroundActions} from "../playground";
import {CoreNotificationService} from "@mods/shared/services/core-notification.service";
import {LocalProfileHelpers} from "../../shared";
import {OneProfileOrionTenantItem} from "@mods/shared";


@State<ILoginAsStateModel>({
  name: 'loginAs',
  defaults: <ILoginAsStateModel>{
    loading: false,
    busy: false,
    current: null,
    personalToken: null,
    loadedTokens: null,
    currentOneProfile: null,
    aggregatedOrionProfiles: [],
    currentSelectedProfileSelection: null
  }
})
@Injectable()
export class LoginAsState {

  constructor(
    private readonly crypto: CryptoService,
    private readonly httpClient: HttpClient,
    private readonly coreNotificationService: CoreNotificationService
  ) {
  }

  get basePath() {
    return 'security';
  }

  @Selector()
  static IsLoading(state: ILoginAsStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static IsWorking(state: ILoginAsStateModel): boolean {
    return state.busy;
  }

  @Selector()
  static getCurrent(state: ILoginAsStateModel): LoginAsType | null {
    return state.current;
  }

  @Selector()
  static hasOneProfileToken(state: ILoginAsStateModel): boolean {
    return !!state.loadedTokens?.OneProfile;
  }

  @Selector()
  static hasOasToken(state: ILoginAsStateModel): boolean {
    return !!state.loadedTokens?.OasToken;
  }

  @Selector()
  static getOneProfileSelectedProfileId(state: ILoginAsStateModel): string | null {
    return state.currentOneProfile?.context?.selected_profile_id ?? null;
  }
  @Selector()
  static getOneProfileAggregatedOrionProfiles(state: ILoginAsStateModel): Array<OneProfileOrionTenantItem> {
    return state.aggregatedOrionProfiles;
  }

  @Action(LoginAsActions.Done)
  onDone(ctx: StateContext<ILoginAsStateModel>) {
    ctx.patchState({
      loading: false,
      busy: false
    });
  }

  @Action(LoginAsActions.Loading)
  onLoading(ctx: StateContext<ILoginAsStateModel>) {
    ctx.patchState({
      loading: true
    });
  }

  @Action(LoginAsActions.Working)
  onWorking(ctx: StateContext<ILoginAsStateModel>) {
    ctx.patchState({
      busy: true
    });
  }

  @Action(LoginAsActions.Login)
  login(ctx: StateContext<ILoginAsStateModel>, action: LoginAsActions.Login) {
    const {username, password} = action.request;
    const payload = <LoginAsRequestType>{
      password: this.crypto.encodeText(password),
      username: this.crypto.encodeText(username),
    }
    return ctx.dispatch(new LoginAsActions.Working()).pipe(
      mergeMap(() => {
        return this.httpClient.post<LoginAsTokenResponse>(`${this.basePath}/login`, payload)
      }),
      tap(personalToken => {
        ctx.patchState({
          personalToken
        })
        ctx.dispatch(new LoginAsActions.LoginSuccess());
      })
    )


  }

  @Action(LoginAsActions.LoginSuccess)
  loginSuccess(ctx: StateContext<ILoginAsStateModel>) {
    ctx.patchState({
      busy: false,
    });
  }

  @Action(LoginAsActions.LoadApplicableTokensForHydration)
  loadGlobalEntityTokenForHydration(ctx: StateContext<ILoginAsStateModel>) {
    const {personalToken} = ctx.getState();
    return ctx.dispatch(new LoginAsActions.Loading()).pipe(
      mergeMap(() => {
        return forkJoin([
          this.httpClient.post<string>(`${this.basePath}/token/oas-token`, personalToken),
          this.httpClient.post<string>(`${this.basePath}/token/one-profile`, personalToken)
        ])
      }),
      tap(([oasToken, oneProfileToken]) => {

        const loadedTokens = <LoadedTokensForHydrationType>{
          OneProfile: oneProfileToken,
          OasToken: oasToken,
          Element: ''
        }
        ctx.patchState({
          loadedTokens,
        })
        ctx.dispatch(new LoginAsActions.Done());

        if(oneProfileToken){
          const oneProfile = LocalProfileHelpers.decodeOneProfile(oneProfileToken);
          if(oneProfile){
            ctx.dispatch(new LoginAsActions.ApplyCurrentOneProfile(oneProfile));
          }
        }

      }),
    );
  }

  @Action(LoginAsActions.ApplyTokenType)
  applyTokenType(ctx: StateContext<ILoginAsStateModel>, action: LoginAsActions.ApplyTokenType) {
    const {tokenType} = action;
    const {loadedTokens} = ctx.getState();
    // @ts-ignore
    const token = loadedTokens[tokenType];
    ctx.dispatch(new PlaygroundActions.ApplyToken(token));
    this.coreNotificationService.displayMessage('Profile Applied', {delay: 3000});
  }

  @Action(LoginAsActions.ApplyCurrentOneProfile)
  applyCurrentOneProfile(ctx: StateContext<ILoginAsStateModel>, action: LoginAsActions.ApplyCurrentOneProfile) {
    const {currentOneProfile} = action;

    const aggregatedOrionProfiles = currentOneProfile?.one_profile?.orion_profiles?.reduce<Array<OneProfileOrionTenantItem>>((acc, orionTenant) => {
      return [...acc, ...orionTenant.profiles]
    }, []);

    ctx.patchState({
      currentOneProfile,
      aggregatedOrionProfiles
    })
  }
  @Action(LoginAsActions.TriggerSelectedProfileChange)
  triggerSelectedProfileChange(ctx: StateContext<ILoginAsStateModel>, action: LoginAsActions.TriggerSelectedProfileChange) {
    const {selectedProfileId} = action;
    const {currentOneProfile, loadedTokens} = ctx.getState();
    if(currentOneProfile){
      const updatedOneProfile = {
        ...currentOneProfile,
        context: {
          ...currentOneProfile.context,
          selected_profile_id: selectedProfileId
        }
      }

      const onProfileEncoded = LocalProfileHelpers.encodeOneProfile(updatedOneProfile);
      let updatedLoadedTokens = <LoadedTokensForHydrationType>{
        ...loadedTokens,
        OneProfile: onProfileEncoded
      }

      ctx.patchState({
        currentOneProfile: updatedOneProfile,
        loadedTokens: updatedLoadedTokens
      })
    }
  }

  @Action(LoginAsActions.ChangeCurrentSelectedProfileSelection)
  changeCurrentSelectedProfileSelection(ctx: StateContext<ILoginAsStateModel>, action: LoginAsActions.ChangeCurrentSelectedProfileSelection) {
    const {selectedProfileId} = action;
    ctx.patchState({
      currentSelectedProfileSelection: selectedProfileId
    })
  }
  @Action(LoginAsActions.ReEstablishOneProfileSelectedProfile)
  reEstablishOneProfileSelectedProfile(ctx: StateContext<ILoginAsStateModel>) {
    const {currentOneProfile, currentSelectedProfileSelection} = ctx.getState();
    if(currentOneProfile && currentSelectedProfileSelection){
      ctx.dispatch(new LoginAsActions.TriggerSelectedProfileChange(currentSelectedProfileSelection));
    }
  }



}
