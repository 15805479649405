import {OneProfilePayloadDto} from "@mods/shared";
import {PlaygroundHelper} from "./playground-helper";

export namespace LocalProfileHelpers {

  export const decodeBase64 = (input: string): string => {
    return atob(input);
  }

  export const ToType = <T>(input: string): T => {
    return JSON.parse(input) as T;
  }

  export const getLocalOneProfile = (): OneProfilePayloadDto | null => {
    if (PlaygroundHelper.HasOneProfileToken()) {
      const token = PlaygroundHelper.RetrieveToken()?.replace('OneProfileToken ', '');
      if (token) {
        return decodeOneProfile(token);
      }
    }
    return null;
  }

  export const decodeOneProfile = (input: string): OneProfilePayloadDto | null => {
    return ToType<OneProfilePayloadDto>(decodeBase64(input));
  }
  export const encodeOneProfile = (input: OneProfilePayloadDto): string => {
    return btoa(JSON.stringify(input));
  }


}
