import {ElementRef, Injectable, TemplateRef, ViewContainerRef} from "@angular/core";
import {Overlay} from "@angular/cdk/overlay";
import {TemplatePortal} from "@angular/cdk/portal";

export interface  CoreOverlayHelperServiceConfig {
  elementRef: ElementRef<any>;
  viewContainerRef: ViewContainerRef,
  templateRef: TemplateRef<any>;
  delay: number;
  width: number;
}

@Injectable({
  providedIn: 'root'
})
export class CoreOverlayHelperService {
  constructor(private readonly overlay: Overlay) {
  }

  createOverlay(config: CoreOverlayHelperServiceConfig) {
    return this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      panelClass: 'cdk-overlay-core-helper',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(config.elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ]),
      scrollStrategy: this.overlay.scrollStrategies.close(),
      width: `${config.width}px`
    });

  }
  displayOverlay(config: CoreOverlayHelperServiceConfig) {
    const overlayRef = this.createOverlay(config);

    const templatePortal = new TemplatePortal(
      config.templateRef,
      config.viewContainerRef
    );

    overlayRef.attach(templatePortal);

    setTimeout(() => {
      overlayRef.detach();
      overlayRef.dispose();
    }, config.delay);

  }


}
